<template>

  <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 5.63043C3.04844 5.63043 6.0534 5.63043 9.0801 5.63043C9.08554 5.72432 9.09097 5.80778 9.09097 5.89124C9.09097 7.7899 9.09097 9.68855 9.09097 11.582C9.09097 12.6878 8.32479 13.4181 7.1728 13.4181C5.41764 13.4181 3.65704 13.4181 1.90188 13.4181C0.766185 13.4181 0 12.6826 0 11.5976C0 9.69899 0 7.80033 0 5.90689C0 5.81821 0 5.73475 0 5.63043ZM2.70066 7.58125C2.7061 7.58125 2.7061 7.58125 2.70066 7.58125C2.70066 7.47693 2.70066 7.37261 2.70066 7.26829C2.70066 7.15353 2.7061 7.04399 2.70066 6.92924C2.67893 6.63714 2.48874 6.44936 2.21161 6.44414C1.92361 6.43893 1.72798 6.61106 1.71712 6.91359C1.70081 7.34653 1.70081 7.77946 1.71712 8.2124C1.72798 8.51493 1.92361 8.68706 2.21161 8.68185C2.48874 8.67663 2.6898 8.49407 2.70066 8.20197C2.71153 7.99854 2.70066 7.7899 2.70066 7.58125Z" fill="#7B858F"/>
    <path d="M9.09657 4.82714C6.04813 4.82714 3.03773 4.82714 0.00558891 4.82714C0.00558891 4.7489 0.00558891 4.68109 0.00558891 4.61328C0.00558891 3.69525 0.000154973 2.77721 0.00558891 1.85918C0.0110229 0.857691 0.798947 0.106573 1.84226 0.106573C3.6572 0.101357 5.46671 0.101357 7.28164 0.106573C8.31409 0.106573 9.09114 0.857691 9.09657 1.84875C9.09657 2.83459 9.09657 3.82043 9.09657 4.82714ZM2.70083 2.91805C2.70626 2.91805 2.70626 2.91805 2.70083 2.91805C2.70083 2.71984 2.70083 2.52163 2.70083 2.32341C2.70083 2.30777 2.70083 2.2869 2.70083 2.27125C2.67909 1.98437 2.48347 1.78616 2.21177 1.78094C1.94008 1.77572 1.72272 1.9635 1.71185 2.2556C1.69555 2.68854 1.69555 3.12148 1.71185 3.55441C1.72272 3.85695 1.91834 4.02908 2.20633 4.02386C2.4889 4.02386 2.68453 3.83608 2.70083 3.54398C2.7117 3.33012 2.70083 3.12148 2.70083 2.91805Z" fill="#7B858F"/>
  </svg>


</template>
<script>
export default {
  name: 'CrossIcon',
}
</script>
<style lang="scss" scoped>
</style>