<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="location-main-title">{{ $t('property-location-title') }}</div>
    <div class="single-property-map"></div>
    <div class="map-description"> {{ description }}</div>

  </div>

</template>
