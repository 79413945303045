<template>

  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7026 4.72224C10.132 4.64921 9.57773 4.57618 9.00173 4.50316C8.99086 4.37276 8.97999 4.21106 8.95283 4.04936C8.81154 3.19914 8.1323 2.62015 7.24114 2.60972C6.27389 2.59407 5.31209 2.59407 4.34485 2.60972C3.37217 2.62536 2.64946 3.32954 2.61142 4.26322C2.60598 4.34668 2.61142 4.43535 2.61142 4.50316C2.03542 4.57618 1.48116 4.64399 0.872559 4.72224C0.921464 4.76396 0.899728 4.75353 0.899728 4.7431C0.899728 3.78855 0.850822 2.83401 0.916029 1.88468C0.992105 0.836244 1.8235 0.126855 2.92115 0.116423C4.84477 0.100774 6.76838 0.0955582 8.68656 0.116423C9.82769 0.126855 10.6645 0.914485 10.708 2.01508C10.7406 2.90703 10.7134 3.79899 10.7134 4.69094C10.708 4.72224 10.6971 4.7431 10.7026 4.72224Z" fill="#7B858F"/>
    <path d="M5.79554 7.88842C3.9643 7.88842 2.13307 7.8832 0.296396 7.89363C0.0790388 7.89363 -0.0187718 7.84669 0.00296397 7.62239C0.0355676 7.27813 0.00296365 6.92344 0.0790388 6.58961C0.285528 5.70809 1.07345 5.11867 2.05156 5.11345C4.5403 5.10302 7.03448 5.0978 9.52323 5.11345C10.6807 5.11867 11.5066 5.91151 11.5664 7.02254C11.5827 7.299 11.5555 7.57545 11.5447 7.84669C11.2675 7.86233 10.9958 7.88842 10.7187 7.88842C9.08308 7.89363 7.44203 7.88842 5.79554 7.88842Z" fill="#7B858F"/>
    <path d="M5.82798 8.43612C7.64835 8.43612 9.47415 8.44134 11.2945 8.43091C11.5173 8.43091 11.5988 8.48829 11.5825 8.70736C11.5662 8.96817 11.5825 9.22897 11.5771 9.48456C11.5662 9.8862 11.3489 10.0948 10.9359 10.0948C7.50706 10.0948 4.07825 10.0948 0.649433 10.0948C0.252755 10.0948 0.029965 9.89142 0.0136632 9.50542C0.00279534 9.22897 0.0245308 8.95252 0.00822895 8.67606C-0.00263893 8.47264 0.0843038 8.43091 0.274492 8.43091C2.12746 8.44134 3.975 8.43612 5.82798 8.43612Z" fill="#7B858F"/>
    <path d="M3.21442 4.54487C3.13835 3.679 3.61653 3.17303 4.50227 3.17303C5.37713 3.17303 6.25199 3.17303 7.12686 3.17303C7.97999 3.17303 8.46904 3.69986 8.3821 4.54487C6.66498 4.54487 4.94785 4.54487 3.21442 4.54487Z" fill="#7B858F"/>
    <path d="M0.606117 10.6738C1.18211 10.6738 1.73638 10.6738 2.29064 10.6738C2.40475 11.2111 2.16566 11.4928 1.6114 11.4928C1.52989 11.4928 1.44838 11.4928 1.36687 11.4928C0.763701 11.4928 0.568079 11.2945 0.606117 10.6738Z" fill="#7B858F"/>
    <path d="M9.28389 10.6738C9.85989 10.6738 10.4142 10.6738 10.9684 10.6738C11.0608 11.2424 10.838 11.4928 10.2511 11.4928C10.1805 11.4928 10.1044 11.4928 10.0338 11.4928C9.43061 11.4928 9.22955 11.2789 9.28389 10.6738Z" fill="#7B858F"/>
  </svg>


</template>
<script>
export default {
  name: 'AmenityIcon',
}
</script>
<style lang="scss" scoped>
</style>