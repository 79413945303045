<template>

  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4066_22980)">
      <path d="M3.521 5.55936C3.66545 5.59604 3.79429 5.63271 3.92312 5.66571C4.08709 5.70605 4.22374 5.64371 4.26278 5.51536C4.30182 5.37967 4.22374 5.26599 4.05977 5.21832C3.91922 5.17798 3.78258 5.13764 3.64203 5.0973C3.63813 5.0973 3.63422 5.08997 3.62641 5.07897C3.68888 4.80027 3.75134 4.51423 3.81771 4.20986C3.96216 4.24653 4.0949 4.2832 4.22764 4.31621C4.40723 4.36021 4.54387 4.3052 4.58291 4.17319C4.62586 4.03384 4.54777 3.92015 4.36819 3.86881C4.22374 3.82848 4.07929 3.78814 3.93874 3.75147C3.93483 3.16472 4.36038 2.81268 4.96941 2.87502C6.1094 2.9887 7.17912 2.80901 8.09657 2.13059C8.72123 1.66853 9.1741 1.07079 9.52546 0.410704C9.71676 0.0476579 9.78704 0.021988 10.1384 0.24935C10.888 0.733412 11.5751 1.28348 12.0748 2.01691C12.2739 2.31028 12.4301 2.62566 12.4808 2.9777C12.4887 3.03271 12.4574 3.11338 12.4106 3.15006C11.3409 4.00817 10.2711 4.86628 9.19362 5.72072C9.15067 5.75372 9.0804 5.77939 9.02965 5.77206C8.19808 5.61804 7.39384 5.73172 6.59741 5.97008C6.01961 6.14244 5.47304 6.37714 4.94989 6.66684C4.77811 6.76219 4.62976 6.76219 4.46188 6.65951C4.15346 6.47615 3.84114 6.30013 3.52491 6.12777C3.43511 6.0801 3.40388 6.02876 3.43511 5.92974C3.47415 5.80873 3.48977 5.68771 3.521 5.55936Z" fill="#7B858F"/>
      <path d="M0.148085 12.1784C-0.1252 11.7714 -0.0119819 11.1626 0.409658 10.8143C0.714175 10.5612 1.01869 10.3119 1.33492 10.0772C2.43587 9.2594 3.18155 8.21793 3.56415 6.95277C3.58367 6.89042 3.60709 6.83175 3.62661 6.76941C3.63052 6.76208 3.63832 6.75474 3.65785 6.74007C3.82182 6.83175 3.9936 6.91976 4.14976 7.02244C4.51674 7.26081 4.87201 7.2498 5.25461 7.03711C5.96906 6.64106 6.72645 6.34769 7.5502 6.22667C7.89376 6.17533 8.24513 6.17167 8.63163 6.146C8.5965 6.20467 8.59259 6.22301 8.58088 6.23401C8.0226 6.74007 7.53849 7.30848 7.05829 7.88055C6.00419 9.14205 4.68071 10.1028 3.22059 10.9059C2.29142 11.4193 1.3115 11.8301 0.284728 12.1454C0.237879 12.1564 0.19103 12.1674 0.148085 12.1784Z" fill="#7B858F"/>
      <path d="M0.116699 12.6883C0.311903 12.6259 0.475874 12.5746 0.639845 12.5196C2.87297 11.7861 4.86405 10.675 6.5428 9.10546C7.03471 8.64707 7.44464 8.11167 7.90142 7.61661C8.29573 7.19489 8.70175 6.7805 9.11949 6.34778C9.29127 6.5348 9.43181 6.68882 9.55284 6.82817C9.41229 6.97486 9.26784 7.11054 9.13901 7.25723C8.68614 7.75229 8.22546 8.24369 7.7882 8.75342C6.89807 9.78755 5.82836 10.6347 4.64933 11.3608C3.41954 12.1162 2.09216 12.6846 0.68279 13.0843C0.553955 13.121 0.479778 13.1026 0.401696 13C0.323615 12.8973 0.226013 12.8056 0.116699 12.6883Z" fill="#7B858F"/>
      <path d="M9.52588 6.06902C9.89286 5.77565 10.252 5.48594 10.6112 5.19991C11.4233 4.55082 12.2392 3.90541 13.0474 3.25266C13.145 3.17198 13.1957 3.17565 13.2777 3.27099C13.5041 3.53502 13.7423 3.78806 14.0038 4.07776C13.957 4.10343 13.9102 4.12543 13.875 4.15477C12.735 5.06422 11.595 5.97 10.459 6.88312C10.377 6.94913 10.3262 6.96013 10.2481 6.87212C10.0178 6.60442 9.77574 6.34405 9.52588 6.06902Z" fill="#7B858F"/>
    </g>
    <defs>
      <clipPath id="clip0_4066_22980">
        <rect width="14" height="13" fill="white" transform="translate(0 0.102661)"/>
      </clipPath>
    </defs>
  </svg>



</template>
<script>
export default {
  name: 'CrossIcon',
}
</script>
<style lang="scss" scoped>
</style>