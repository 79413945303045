<template>

  <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4066_22966)">
      <path d="M4.57278 17C4.28023 16.8892 4.16941 16.6718 4.17827 16.3658C4.19157 15.9223 4.17827 15.4788 4.18271 15.0397C4.18271 14.9332 4.15611 14.8933 4.04086 14.8712C3.37597 14.7425 2.70664 14.6183 2.04618 14.4587C1.41232 14.3034 1.00452 13.789 0.942459 13.137C0.933594 13.0616 0.933594 12.9818 0.933594 12.9064C0.933594 9.30501 0.933594 5.70366 0.933594 2.1023C0.933594 1.32615 1.3414 0.745139 2.03732 0.554427C2.42739 0.447983 2.81746 0.354844 3.21196 0.279446C4.58164 0.00446602 5.96462 -0.0576264 7.35203 0.084299C8.18537 0.168567 9.00097 0.323798 9.80771 0.549992C10.4948 0.745139 10.9114 1.29954 10.9292 2.02247C10.9336 2.20431 10.9292 2.38615 10.9292 2.57243C10.9292 6.00081 10.9292 9.43363 10.9292 12.862C10.9292 13.7579 10.5258 14.299 9.657 14.5119C9.0453 14.6583 8.42473 14.7647 7.80859 14.8845C7.70664 14.9022 7.68448 14.9421 7.68448 15.0308C7.68891 15.4788 7.67561 15.9267 7.68891 16.3747C7.69778 16.6807 7.58696 16.898 7.29441 17.0089C7.21905 17.0089 7.13927 17.0089 7.06391 17.0089C6.77579 16.8892 6.66941 16.6718 6.68271 16.3658C6.70044 15.9844 6.68714 15.603 6.68714 15.2215C6.68714 15.1461 6.68714 15.0707 6.68714 14.9998C6.17296 14.9998 5.68537 14.9998 5.18448 14.9998C5.18448 15.461 5.17118 15.9134 5.18891 16.3658C5.19778 16.6718 5.09583 16.8892 4.80771 17.0089C4.72792 17 4.64813 17 4.57278 17ZM3.43803 5.0029C3.43803 6.38667 4.55948 7.50434 5.93803 7.4999C7.31214 7.49547 8.42473 6.38224 8.42473 5.0029C8.42473 3.61913 7.31214 2.49703 5.93359 2.49703C4.55505 2.49703 3.43803 3.61913 3.43803 5.0029Z" fill="#7B858F"/>
      <path d="M7.42732 5.00291C7.42732 5.83229 6.75357 6.502 5.9291 6.502C5.10463 6.502 4.4353 5.82785 4.4353 4.99848C4.4353 4.1691 5.10463 3.49939 5.93353 3.49939C6.76243 3.49939 7.42732 4.17354 7.42732 5.00291ZM6.15959 4.24893C6.11083 4.26224 6.02218 4.26224 5.96899 4.30659C5.79612 4.45739 5.61881 4.61262 5.48583 4.79446C5.36172 4.96743 5.43264 5.22023 5.59222 5.3666C5.75179 5.51739 6.00888 5.55287 6.18619 5.41982C6.3369 5.3045 6.47874 5.16701 6.59399 5.01622C6.70924 4.86542 6.70481 4.68358 6.61615 4.51504C6.5275 4.34207 6.37679 4.26224 6.15959 4.24893Z" fill="#7B858F"/>
    </g>
    <defs>
      <clipPath id="clip0_4066_22966">
        <rect width="10" height="17" fill="white" transform="translate(0.933594)"/>
      </clipPath>
    </defs>
  </svg>



</template>
<script>
export default {
  name: 'AmenityIcon',
}
</script>
<style lang="scss" scoped>
</style>