<template>

  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4066_22974)">
      <path d="M10.0038 13C9.93822 13 9.87266 13 9.80711 13C9.57767 12.8982 9.48589 12.7185 9.51211 12.4641C9.52195 12.3725 9.51211 12.2809 9.51211 12.1962C6.88339 12.1962 4.27761 12.1962 1.65872 12.1962C1.65872 12.2945 1.65216 12.3861 1.65872 12.4777C1.68166 12.7286 1.58661 12.9016 1.36372 13C1.29817 13 1.23261 13 1.16706 13C0.944176 12.9016 0.849123 12.7252 0.872067 12.4743C0.8819 12.3556 0.875345 12.2369 0.875345 12.1215C0.370577 11.9079 0.0755828 11.4636 0.0821383 10.8022C0.0985268 9.14711 0.0886937 7.4954 0.0886937 5.8403C0.0886937 5.79281 0.0886937 5.74194 0.0886937 5.69785C0.957287 5.69785 1.80949 5.69785 2.66498 5.69785C2.06843 7.34617 2.78625 8.80116 3.68434 9.49644C4.70699 10.2901 6.10657 10.4088 7.18494 9.69315C8.62713 8.73672 9.00407 7.35973 8.52552 5.69446C9.3679 5.69446 10.2234 5.69446 11.0854 5.69446C11.0854 5.73177 11.0854 5.7589 11.0854 5.78942C11.0854 7.51236 11.0854 9.2319 11.0821 10.9548C11.0821 11.06 11.069 11.1651 11.0428 11.2669C10.9412 11.684 10.6823 11.9587 10.2988 12.1215C10.2988 12.2402 10.2922 12.359 10.2988 12.4777C10.3184 12.7252 10.2267 12.9016 10.0038 13Z" fill="#7B858F"/>
      <path d="M0.0888672 4.87034C0.0888672 4.81607 0.0888672 4.77537 0.0888672 4.73467C0.0888672 3.58831 0.0888672 2.44195 0.0888672 1.29559C0.0888672 0.518915 0.587079 0 1.33112 0C4.16634 0 6.99828 0 9.8335 0C10.5808 0 11.0823 0.515523 11.0823 1.28542C11.0823 2.43517 11.0823 3.58831 11.0823 4.73806C11.0823 4.77876 11.0823 4.81946 11.0823 4.87373C11.0299 4.87373 10.9905 4.87373 10.9512 4.87373C10.0203 4.87373 9.08619 4.87373 8.15532 4.87712C8.0701 4.87712 8.01437 4.85338 7.95865 4.78555C6.70984 3.28307 4.45806 3.28646 3.20597 4.78894C3.16992 4.82964 3.10764 4.87373 3.05847 4.87373C2.08827 4.87712 1.12135 4.87712 0.151144 4.87712C0.134755 4.87373 0.118367 4.87034 0.0888672 4.87034ZM2.83559 2.03157C2.70776 2.03157 2.58321 2.02818 2.45538 2.03157C2.22594 2.03496 2.05222 2.20793 2.05222 2.43517C2.05222 2.66241 2.22266 2.83877 2.4521 2.84216C2.71104 2.84555 2.9667 2.84555 3.22564 2.84216C3.45508 2.83877 3.62552 2.65901 3.62224 2.43178C3.61896 2.21132 3.44852 2.03496 3.22891 2.03157C3.09781 2.02818 2.9667 2.03157 2.83559 2.03157ZM5.59215 2.84216C5.72325 2.84216 5.85436 2.84555 5.98547 2.84216C6.20508 2.83538 6.37224 2.65562 6.37224 2.43517C6.37224 2.21471 6.2018 2.03496 5.97891 2.03157C5.7167 2.02818 5.45448 2.02818 5.19554 2.03157C4.97266 2.03496 4.80549 2.21132 4.80222 2.43178C4.79894 2.65901 4.97266 2.83538 5.19882 2.84216C5.32993 2.84555 5.46104 2.84216 5.59215 2.84216ZM8.33559 2.03157C8.20448 2.03157 8.07337 2.02818 7.94226 2.03157C7.71938 2.03835 7.55222 2.21132 7.54894 2.43517C7.54566 2.65562 7.7161 2.83877 7.93571 2.84216C8.2012 2.84555 8.4667 2.84555 8.73219 2.84216C8.9518 2.83877 9.11896 2.65562 9.11896 2.43517C9.11896 2.21471 8.94852 2.03835 8.72564 2.03157C8.59453 2.02818 8.46342 2.03157 8.33559 2.03157Z" fill="#7B858F"/>
      <path d="M7.32575 5.28405C7.18808 5.34849 7.04059 5.39937 6.91275 5.48416C6.56859 5.71139 6.38832 6.04716 6.37521 6.46772C6.36538 6.75601 6.36865 7.04429 6.37521 7.32919C6.38177 7.86167 6.7292 8.32632 7.22414 8.47555C7.26347 8.48572 7.29952 8.49929 7.33886 8.50946C6.84392 9.14708 5.83439 9.55747 4.85107 9.21492C3.85465 8.86897 3.21222 7.9295 3.23188 6.83741C3.24827 5.79619 3.94315 4.8635 4.92318 4.56503C5.91305 4.26657 6.88653 4.71087 7.32575 5.28405Z" fill="#7B858F"/>
      <path d="M7.1556 6.90185C7.1556 6.77636 7.1556 6.64748 7.1556 6.52199C7.15888 6.26762 7.31949 6.10143 7.56204 6.09465C7.81442 6.08786 7.81442 6.08786 7.8767 6.34902C7.97831 6.77636 7.95864 7.20031 7.83737 7.62426C7.8177 7.69548 7.78493 7.7294 7.70954 7.72262C7.65382 7.71583 7.59482 7.72262 7.5391 7.71922C7.32605 7.70905 7.16544 7.53947 7.15888 7.31902C7.15233 7.17996 7.1556 7.0409 7.1556 6.90185Z" fill="#7B858F"/>
    </g>
    <defs>
      <clipPath id="clip0_4066_22974">
        <rect width="11" height="13" fill="white" transform="translate(0.0820312)"/>
      </clipPath>
    </defs>
  </svg>


</template>
<script>
export default {
  name: 'CrossIcon',
}
</script>
<style lang="scss" scoped>
</style>