<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="amenities-main-container" v-if="propertyAppliances && amenities">
      <div class="row single-amenity">
        <div v-for="appliance in propertyAppliances" class="col-md-4 col-12 d-flex align-items-center">
          <div class="yes-no-svg">
            <div v-if="hasAmenity(appliance)">
              <TickIcon/>
            </div>
            <div v-else >
              <CrossIcon/>
            </div>

          </div>
          <div class="ms-2">
            <AmenityIcon :icon="appliance.name"/>
          </div>
          <div class="amenity d-flex align-items-center ms-2">
            <div class="ms-1">{{ $t(appliance.name) }}</div>
          </div>
          <div class="buy-button ms-2" v-if="!hasAmenity(appliance)">
            <Button @click="$router.push({name: 'MarketplaceCategoryPage', params: {id: appliance.categoryId}})"
                    :size="'super-small'"
                    :theme="'solid-orange'"
                    :text="'Купи'" :svg="'bag'"/>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

