<template>

  <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.00243847 7.13946C0.00243847 5.89678 -0.00304808 4.70359 0.00243847 3.51041C0.00243847 3.20249 0.238361 2.97705 0.518175 2.98255C0.792503 2.98804 1.01745 3.21348 1.02294 3.5269C1.03391 4.07126 1.02842 4.62112 1.02842 5.16547C1.02842 5.26445 1.02842 5.36342 1.02842 5.47339C5.01166 5.47339 8.97295 5.47339 12.9672 5.47339C12.9672 4.92354 12.9672 4.37918 12.9672 3.83482C12.9672 3.69736 12.9617 3.55989 12.9836 3.42793C13.0275 3.153 13.2689 2.96055 13.5213 2.98255C13.7847 3.00454 13.9877 3.21348 13.9931 3.49391C14.0041 3.9063 13.9986 4.3132 13.9986 4.72559C13.9986 5.51738 13.9986 6.30917 13.9986 7.11746C13.7243 7.11746 13.4719 7.11746 13.1866 7.11746C13.1866 7.45837 13.1866 7.78279 13.1866 8.1182C12.9013 8.1182 12.6489 8.1182 12.3746 8.1182C12.3746 7.80478 12.3746 7.48587 12.3746 7.15045C8.79189 7.15045 5.23661 7.15045 1.64292 7.15045C1.64292 7.46387 1.64292 7.78829 1.64292 8.1237C1.35762 8.1237 1.10524 8.1237 0.830908 8.1237C0.830908 7.79928 0.830908 7.48587 0.830908 7.13946C0.551094 7.13946 0.293226 7.13946 0.00243847 7.13946Z" fill="#7B858F"/>
    <path d="M6.56419 4.65952C4.98955 4.65952 3.44234 4.65952 1.85673 4.65952C1.85673 4.32411 1.85124 3.9997 1.85673 3.67528C1.8677 3.04845 1.63727 2.56457 1.05569 2.26765C0.99534 2.24016 0.940474 2.13568 0.934988 2.0642C0.924015 1.7068 0.924015 1.35489 0.934988 0.997483C0.951447 0.519108 1.30259 0.12871 1.77992 0.123212C3.10218 0.106716 4.42443 0.106716 5.74669 0.123212C6.1527 0.12871 6.54773 0.453125 6.5587 0.838024C6.58065 2.10819 6.56419 3.37286 6.56419 4.65952Z" fill="#7B858F"/>
    <path d="M12.1494 4.65409C10.5638 4.65409 9.00565 4.65409 7.43101 4.65409C7.42552 4.55512 7.41455 4.46714 7.41455 4.37367C7.41455 3.31794 7.40906 2.25672 7.41455 1.201C7.41455 0.453194 7.75471 0.112284 8.4954 0.106785C9.66403 0.101286 10.8272 0.101286 11.9958 0.106785C12.72 0.106785 13.0657 0.464191 13.0712 1.179C13.0712 1.31647 13.0602 1.45393 13.0712 1.5914C13.0986 1.9598 13.0712 2.25672 12.6597 2.47117C12.2701 2.67461 12.1494 3.1145 12.144 3.55438C12.1494 3.91729 12.1494 4.27469 12.1494 4.65409Z" fill="#7B858F"/>
  </svg>


</template>
<script>
export default {
  name: 'AmenityIcon',
}
</script>
<style lang="scss" scoped>
</style>