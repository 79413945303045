<template>

  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.49441 3.22192C3.20626 3.22192 3.86376 3.22192 4.52127 3.22192C5.1027 3.22192 5.1027 3.22192 5.30919 3.74353C5.41787 4.01477 5.46134 4.39555 5.67327 4.53116C5.89606 4.672 6.28187 4.58332 6.5916 4.58854C7.05892 4.59376 7.53167 4.57811 7.99899 4.59376C8.18918 4.59897 8.27069 4.53116 8.33046 4.36946C8.44458 4.04085 8.57499 3.71745 8.71627 3.40449C8.74888 3.33146 8.85755 3.23757 8.93363 3.23236C9.71068 3.22192 10.4877 3.22714 11.2648 3.22714C11.2865 3.22714 11.3137 3.24279 11.368 3.25844C11.2594 3.53489 11.1561 3.80613 11.0529 4.07736C10.8627 4.56246 10.6562 5.04234 10.4823 5.53265C10.4334 5.66306 10.4334 5.8404 10.4823 5.97081C10.8898 7.04011 11.3191 8.10419 11.743 9.16827C11.9169 9.61164 11.7593 9.83072 11.2702 9.83072C10.7812 9.83593 10.2975 9.8255 9.80849 9.83072C9.53136 9.83593 9.35748 9.73161 9.25967 9.47081C8.96623 8.69882 8.65107 7.93727 8.35763 7.16529C8.28156 6.95665 8.16744 6.89406 7.94465 6.89927C7.25998 6.91492 6.56987 6.9097 5.88519 6.89927C5.695 6.89406 5.61893 6.96186 5.55372 7.12356C5.25485 7.90076 4.93968 8.67274 4.64082 9.44994C4.53757 9.71596 4.36912 9.83593 4.07569 9.83072C3.5812 9.82028 3.08128 9.83072 2.58679 9.8255C2.13034 9.82028 1.97275 9.59078 2.13577 9.1787C2.55962 8.11462 2.9889 7.05054 3.39645 5.98124C3.45079 5.83519 3.45079 5.63698 3.39645 5.49093C3.11388 4.75546 2.80958 4.01477 2.49441 3.22192Z" fill="#7B858F"/>
    <path d="M6.93407 2.43426C5.04849 2.43426 3.16292 2.43947 1.27191 2.43426C0.516588 2.42904 -0.0159376 1.91786 0.000364234 1.23455C0.0166661 0.681648 0.451381 0.206983 1.02194 0.128742C1.14693 0.113093 1.27191 0.102661 1.39689 0.102661C5.09197 0.102661 8.78161 0.102661 12.4767 0.102661C13.2103 0.102661 13.6667 0.405195 13.8297 0.989397C14.0254 1.69879 13.4983 2.41339 12.7321 2.42904C11.7648 2.4499 10.7976 2.43947 9.83579 2.43947C8.86855 2.43426 7.90131 2.43426 6.93407 2.43426Z" fill="#7B858F"/>
  </svg>


</template>
<script>
export default {
  name: 'AmenityIcon',
}
</script>
<style lang="scss" scoped>
</style>