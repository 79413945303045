<template>

  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0402 9.01045C12.0402 9.1878 12.0457 9.35993 12.0402 9.52684C12.0294 9.78765 11.8609 9.96499 11.6164 9.98064C11.3827 9.99629 11.1654 9.82938 11.1328 9.579C11.111 9.40166 11.1273 9.21909 11.1273 9.01566C8.32887 9.01566 5.55757 9.01566 2.75365 9.01566C2.75365 9.17736 2.75909 9.32863 2.75365 9.48511C2.73735 9.78765 2.54716 9.98586 2.2809 9.98064C2.0092 9.97543 1.84075 9.78765 1.83532 9.46947C1.82988 9.32341 1.83532 9.17736 1.83532 9.01566C1.75381 9.00523 1.69403 9.00002 1.63426 8.9948C0.721355 8.92177 0.00407545 8.20195 0.00407545 7.32043C-0.00135848 5.47394 -0.00135848 3.62744 0.00407545 1.78616C0.00950939 0.862907 0.781129 0.111789 1.74294 0.106573C5.20979 0.101357 8.67665 0.101357 12.1435 0.106573C13.089 0.106573 13.866 0.857691 13.8715 1.76529C13.8769 3.61701 13.8769 5.47394 13.8715 7.32565C13.866 8.19152 13.1596 8.91134 12.2576 8.98958C12.1978 8.9948 12.1381 9.00002 12.0402 9.01045ZM6.14987 7.23176C7.30729 7.23176 8.45929 7.23176 9.61672 7.23176C10.2036 7.23176 10.5187 6.92923 10.5187 6.36589C10.5187 5.16097 10.5187 3.96127 10.5187 2.75635C10.5187 2.16693 10.2036 1.86961 9.58411 1.86961C7.31273 1.86961 5.03591 1.86961 2.76452 1.86961C2.13418 1.86961 1.80815 2.17215 1.80815 2.772C1.80271 3.95605 1.80271 5.14532 1.80815 6.32937C1.80815 6.92923 2.13418 7.22654 2.76995 7.23176C3.89478 7.23176 5.01961 7.23176 6.14987 7.23176ZM12.225 3.95605C12.6163 3.96127 12.9151 3.68481 12.926 3.31447C12.9369 2.93891 12.6325 2.6416 12.2304 2.6416C11.8501 2.6416 11.5458 2.9337 11.5458 3.29361C11.5458 3.65873 11.8446 3.95084 12.225 3.95605ZM12.225 6.45456C12.6163 6.45978 12.9151 6.18332 12.926 5.8182C12.9369 5.44264 12.6326 5.14532 12.2359 5.14532C11.8555 5.14532 11.5512 5.43221 11.5458 5.79733C11.5403 6.15724 11.8446 6.44935 12.225 6.45456Z" fill="#7B858F"/>
    <path d="M8.49192 4.54539C8.49192 4.15418 8.48648 3.76819 8.49192 3.37698C8.49735 3.05358 8.65494 2.86581 8.93207 2.85537C9.2092 2.84494 9.41026 3.04315 9.41026 3.36655C9.41569 4.15418 9.41569 4.94181 9.41026 5.72944C9.40483 6.04762 9.2255 6.23019 8.9538 6.2354C8.6658 6.2354 8.49735 6.05284 8.49192 5.71379C8.48648 5.32259 8.49192 4.93659 8.49192 4.54539Z" fill="#7B858F"/>
  </svg>


</template>
<script>
export default {
  name: 'CrossIcon',
}
</script>
<style lang="scss" scoped>
</style>