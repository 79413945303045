<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="container" v-if="property">

    <GalleryMain :images="property.images"/>
    <div class="row after-gallery parent" ref="parent">
      <div class="col-md-8 left">
        <div class="property-title-id">
          <SinglePropertyTitleID :title="property.title" :propertyid="'32124'"/>
        </div>
        <div class="col-md-4 contact-container d-md-none">
          <PropertyAdContact :user="property.hasOwnProperty('user') ? property.user : null"
                             :agency="property.hasOwnProperty('agency') ? property.agency : null"
                             :amount="property.price"
                             :date="property.createdAt"
                             :views="'424'"/>
        </div>
        <div class="property-specs-container">
          <SinglePropertyNavigation/>
          <SinglePropertyDescription :description="property.description" id="description"/>
          <SinglePropertySpecification id="features" v-if="propertySettings.type"
                                       :propertytype="$t('property-type-' + property.type)"
                                       :constructiontype="$t('property-construction-' + property.construction)"
                                       :bedroomnumber="$t('property-bedrooms-' + property.bedrooms)"
                                       :bathroomnumber="$t('property-bathrooms-' + property.bathrooms)"
                                       :squaresize="property.areaSqm"
                                       :constructionyear="property.buildingYear"
                                       :heatingtype="$t('property-heating-' + property.heatingtype)"
                                       :furnishment="$t('property-furniture-' + property.furniture)"
                                       :floor="property.floor"
                                       :totalfloors="property.totalFloors"/>
          <SinglePropertyAmenities :amenities="property.appliances"/>


          <div class="features-main-title">{{ $t('property-features-title') }}</div>
          <SinglePropertyFeatures id="advantages" v-for="propertyAdvantage in property.propertyAdvantages"
                                  :title="propertyAdvantage.title" :description="propertyAdvantage.description"/>
          <SinglePropertyUtilities :lowrangetotal="'100'" :lowrangeelectro="property.billsMeta.electricity"
                                   :lowrangeheating="property.billsMeta.heating"
                                   :lowrangewater="property.billsMeta.water"
                                   :lowrangecomunal="property.billsMeta.maintenance"
                                   :description="property.taxDescription"/>
          <SinglePropertyLocation id="location" :description="property.locationAdvantages"/>
        </div>
      </div>
      <div class="col-md-4 right-column d-none d-md-block" ref="rightColumn">
        <PropertyAdContact :user="property.hasOwnProperty('user') ? property.user : null"
                           :agency="property.hasOwnProperty('agency') ? property.agency : null"
                           :amount="property.price"
                           :date="property.createdAt"
                           :views="'424'"/>
      </div>
    </div>
  </div>

  <div class="container" v-if="products && morePropertiesSameCity && morePropertiesSameVendor">
    <SinglePropertyMarketplaceCarousel
        :products="products"
        :tv="'yes'"
        :bed="'yes'"
        :coffeetable="'yes'"
        :sofa="'yes'"
        :washingmachine="'yes'"
        :fridge="'yes'"
        :oven="'yes'"
        :kitchentable="'no'"
        :decorativelights="'no'"
    />
    <div class="row">
      <div class="col-md-6">
        <SinglePropertyAdCarousel :properties="morePropertiesSameCity"/>
      </div>
      <div class="col-md-6">
        <SinglePropertyAdCarousel :properties="morePropertiesSameVendor"/>
      </div>
    </div>
  </div>
  <div v-else>
    <PreLoader :size="'small'"/>
  </div>

  <div class="Footer">
    <Footer/>
  </div>
</template>
