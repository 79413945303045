<template>

  <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.20509 6.7897C9.20509 8.56838 9.20509 10.3419 9.20509 12.1205C9.20509 12.9551 8.71604 13.4194 7.84661 13.4194C5.6839 13.4194 3.52119 13.4194 1.35305 13.4194C0.483619 13.4194 0 12.9499 0 12.1153C0 8.5423 0 4.97449 0 1.40147C0 0.566894 0.489055 0.102661 1.35848 0.102661C3.52119 0.102661 5.6839 0.102661 7.85204 0.102661C8.72147 0.102661 9.20509 0.57211 9.20509 1.40668C9.20509 3.20102 9.20509 4.99536 9.20509 6.7897ZM1.40739 8.59968C1.40195 10.3106 2.83651 11.7033 4.60254 11.698C6.34684 11.698 7.79227 10.3158 7.79227 8.64663C7.7977 6.93053 6.39031 5.55348 4.60798 5.53783C2.86369 5.52218 1.40739 6.90967 1.40739 8.59968ZM5.95016 3.28448C5.9556 2.59595 5.33069 1.99089 4.60798 1.98567C3.8907 1.98046 3.26036 2.58552 3.25493 3.27405C3.24949 3.96257 3.8744 4.56764 4.59711 4.57285C5.31439 4.57807 5.94473 3.97822 5.95016 3.28448Z" fill="#7B858F"/>
    <path d="M4.60803 9.90891C3.87445 9.91412 3.26042 9.32992 3.24955 8.62575C3.24411 7.92157 3.84728 7.33216 4.58086 7.32694C5.33618 7.32172 5.95022 7.90071 5.95022 8.61531C5.95022 9.31949 5.34161 9.90369 4.60803 9.90891Z" fill="#7B858F"/>
  </svg>


</template>
<script>
export default {
  name: 'SoundIcon',
}
</script>
<style lang="scss" scoped>
</style>