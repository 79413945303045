import RadioButton from '@/components/Common/Inputs/RadioButton/RadioButton';

import Dropdown from '@/components/Common/Inputs/Dropdown/Dropdown';
import ToggleSwitch from '@/components/Common/Buttons/ToggleSwitch/ToggleSwitch';
import InputField from '@/components/Common/Inputs/Input/Input';
import Button from '@/components/Common/Buttons/Button/Button';
import PlusMinusInput from '@/components/Common/Inputs/PlusMinusInput/PlusMinusInput';
import HeartIcon from '@/components/Common/Icons/HeartIcon/';
import LabelNew from '@/components/Common/Labels/LabelNew/LabelNew';
import Checkbox from '@/components/Common/Inputs/CheckBox/CheckBox';

export default {
    name: 'SinglePropertyFeatures',
    props: {
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        propertyid: {
            type: String,
            default: '',
        },
    },
    components: {

        Dropdown,
        ToggleSwitch,
        InputField,
        Button,
        PlusMinusInput,
        HeartIcon,
        LabelNew,
        Checkbox,
    },
    mounted() {
    },
};
