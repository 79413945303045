<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>

    <div class="gallery-container d-md-flex" v-if="images">
      <div class="all-images-button">
        <Button :size="'super-small'" :theme="'solid-orange'" :svg="'picture'" :text="'Всички снимки'"/>
      </div>

      <div class="gallery-main-photo col-md-6" v-if="images.length > 0"
           @click="openLightbox(0)"
          v-bind:style="{'background-image': 'url(/storage/' + images[0].src + ')'}">
      </div>
      <div class="gallery-thumb-container row col-md-6" v-if="images.length > 1">
        <div class="d-flex" v-if="images.length > 1">
          <div class="gallery-thumbnail-1 gallery-main-thumbnails col-6" v-if="images.length >= 1"
               @click="openLightbox(1)"
               v-bind:style="{
                  'background-image': (
                      1 in images ? 'url(/storage/' + images[1].src + ')' : 'none'
                  )
              }">
          </div>
          <div class="gallery-thumbnail-2 gallery-main-thumbnails col-6" v-if="images.length > 2"
               @click="openLightbox(2)"
               v-bind:style="{
                  'background-image': (
                      2 in images ? 'url(/storage/' + images[2].src + ')' : 'none'
                  )
              }">
          </div>
        </div>
        <div class="d-flex">
          <div class="gallery-thumbnail-3 gallery-main-thumbnails col-6"
               @click="openLightbox(3)"
               v-bind:style="{
                  'background-image': (
                      3 in images ? 'url(/storage/' + images[3].src + ')' : 'none'
                  )
              }">
          </div>
          <div class="gallery-thumbnail-4 gallery-main-thumbnails col-6"
               @click="openLightbox(4)"
               v-bind:style="{
                  'background-image': (
                      4 in images ? 'url(/storage/' + images[4].src + ')' : 'none'
                  )
              }">
          </div>
        </div>
      </div>

    </div>

    <LightboxModal ref="lightbox" :images="images.map((item) => item.src)"/>

  </div>
</template>

