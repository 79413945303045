<template>

  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.92284 7.73381C4.84707 7.73381 2.7713 7.73381 0.69554 7.73381C0.135844 7.73381 0 7.60341 0 7.0818C0 4.95363 0 2.82546 0 0.697296C0 0.248712 0.146714 0.102661 0.608599 0.102661C4.82534 0.102661 9.03664 0.102661 13.2534 0.102661C13.7207 0.102661 13.8674 0.248712 13.8674 0.69208C13.8674 2.8359 13.8674 4.98493 13.8674 7.12874C13.8674 7.58254 13.7153 7.73381 13.2316 7.73381C11.1287 7.73381 9.02577 7.73381 6.92284 7.73381Z" fill="#7B858F"/>
    <path d="M5.84157 9.11601C5.84157 8.90736 5.84157 8.73523 5.84157 8.54224C6.56429 8.54224 7.2707 8.54224 8.00972 8.54224C8.00972 8.70915 8.00972 8.8865 8.00972 9.11601C8.26511 9.11601 8.49334 9.11601 8.72156 9.11601C9.00956 9.11601 9.29756 9.11079 9.58556 9.12122C9.86812 9.13166 10.0257 9.27771 10.0257 9.51243C10.0257 9.75237 9.87355 9.89321 9.58012 9.89321C7.81952 9.89842 6.05893 9.89842 4.29833 9.89321C4.0049 9.89321 3.84732 9.7628 3.83645 9.52808C3.82558 9.28292 3.9886 9.12644 4.28747 9.12122C4.79826 9.11079 5.30361 9.11601 5.84157 9.11601Z" fill="#7B858F"/>
  </svg>


</template>
<script>
export default {
  name: 'TvIcon',
}
</script>
<style lang="scss" scoped>
</style>