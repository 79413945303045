<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'single-property-marketplace-carousel': true,
  }">
    <div class="d-flex top-nav">
      <div class=" title">Oще имоти в София</div>


    </div>
    <div class="property-ad-carousel    ">
      <Carousel v-bind="settings" :breakpoints="breakpoints">
        <Slide v-for="property in properties" :key="property">
          <div class="slider_item">
            <PropertyAd
                :property="property"
                :id="property.id"
                :adtype="property.isVip ? 'premium' : 'regular'"
                :city="property.city.name"
                :district="property.district.name"
                :title="property.title"
                :amount="property.price"
                :images="property.images"
            />
          </div>
        </Slide>
        <template #addons>
          <Navigation>
            <template #next>
              <div class="buttons-left-right">
                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1747_9026)">
                    <path d="M1 7L17 7" stroke="#434D56" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M11 13L17 7L11 0.999999" stroke="#434D56" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1747_9026 ">
                      <rect width="18" height="14" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </template>
            <template #prev>
              <div class=" buttons-left-right button-left">
                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1747_16541)">
                    <path d="M17 7L1 7" stroke="#434D56" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M7 1L1 7L7 13" stroke="#434D56" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1747_16541">
                      <rect width="18" height="14" fill="white" transform="translate(18 14) rotate(180)"/>
                    </clipPath>
                  </defs>
                </svg>

              </div>
            </template>
          </Navigation>
        </template>

      </Carousel>
    </div>
  </div>

</template>

