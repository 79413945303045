<template>
  <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5.95422L6.82187 10.8058L15.5547 1.10266" stroke="#93C034" stroke-width="1.94062"
          stroke-linecap="round"/>
  </svg>
</template>
<script>
export default {
  name: 'TickIcon',
}
</script>
<style lang="scss" scoped>
</style>