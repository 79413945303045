<template>

  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.0820312 7.16451C0.0820312 5.48493 0.0820312 3.80534 0.0820312 2.12576C0.0820312 1.19208 0.690632 0.602661 1.65787 0.602661C4.51613 0.602661 7.37438 0.602661 10.2326 0.602661C11.1836 0.602661 11.803 1.19208 11.803 2.09968C11.803 5.47971 11.803 8.85974 11.803 12.2398C11.803 13.1317 11.1836 13.7316 10.2598 13.7316C7.37981 13.7316 4.50527 13.7316 1.62528 13.7316C0.71781 13.7316 0.0874705 13.1317 0.0874705 12.2606C0.0820366 10.5654 0.0820312 8.86496 0.0820312 7.16451ZM9.84683 8.58329C9.85226 6.52293 8.10796 4.83291 5.96699 4.8277C3.79885 4.82248 2.03826 6.49163 2.03282 8.56242C2.02739 10.6436 3.77168 12.3337 5.92896 12.3337C8.09166 12.3337 9.84683 10.6541 9.84683 8.58329ZM2.78814 2.01101C2.55448 2.01101 2.32082 2.01101 2.08716 2.01101C2.06543 2.01101 2.04912 2.01101 2.02738 2.01101C1.73939 2.03187 1.54377 2.22487 1.54377 2.48046C1.54377 2.73604 1.73939 2.93947 2.02738 2.94469C2.51644 2.95512 3.0055 2.95512 3.48912 2.94469C3.77712 2.93947 3.97817 2.74648 3.9836 2.49089C3.99447 2.22487 3.78798 2.02144 3.48368 2.01101C3.25546 2.00579 3.0218 2.01101 2.78814 2.01101ZM8.86872 2.47002C8.86329 2.21443 8.64593 2.01101 8.37967 2.01101C8.10254 2.01101 7.87974 2.2353 7.89061 2.50132C7.90148 2.75691 8.12969 2.95512 8.39596 2.94469C8.66222 2.93947 8.87416 2.72561 8.86872 2.47002ZM10.3359 2.4961C10.3413 2.24052 10.1348 2.02666 9.86856 2.01101C9.6023 2.00057 9.37408 2.19879 9.35778 2.44916C9.34147 2.71518 9.55882 2.94469 9.83595 2.94469C10.1022 2.95512 10.3305 2.74648 10.3359 2.4961Z" fill="#7B858F"/>
    <path d="M8.85236 8.61979C8.90126 9.85079 7.86338 11.0766 6.58097 11.3269C4.83124 11.6712 3.22822 10.555 3.0163 8.85451C2.99456 8.66152 3.03804 8.55198 3.23909 8.47895C4.05962 8.1712 4.88558 8.16077 5.70067 8.46852C7.20044 9.03708 7.33629 9.04751 8.85236 8.61979Z" fill="#7B858F"/>
  </svg>



</template>
<script>
export default {
  name: 'CrossIcon',
}
</script>
<style lang="scss" scoped>
</style>