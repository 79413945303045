import Button from "@/components/Common/Buttons/Button/Button";
import HeartIconOrange from "@/components/Common/Icons/HeartIconOrange";

export default {
    name: 'RoommateAdContact',
    props: {
        date: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        views: {
            type: String,
            default: ''
        },
        amount: {
            type: String,
            default: ''
        },
        agency: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        }
    },
    components: {
        Button,
        HeartIconOrange
    },
    mounted() {
    }
}