import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";

export default {
    name: 'SinglePropertySpecification',
    props: {
        propertytype: {
            type: String,
            default: ''
        },
        constructiontype: {
            type: String,
            default: ''
        },
        bedroomnumber: {
            type: String,
            default: ''
        },
        bathroomnumber: {
            type: String,
            default: ''
        },
        squaresize: {
            type: String,
            default: ''
        },
        constructionyear: {
            type: String,
            default: ''
        },
        heatingtype: {
            type: String,
            default: ''
        },
        furnishment: {
            type: String,
            default: ''
        },
        floor: {
            type: String,
            default: ''
        },
        totalfloors: {
            type: String,
            default: ''
        },

    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox

    },
    mounted() {
    }
}