<template>

  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4066_22970)">
      <path d="M1.82795 0C3.63167 0 5.43808 0 7.24449 0C7.39794 0.0349804 7.55409 0.0565068 7.69946 0.110323C8.46672 0.392857 8.95668 0.922945 9.03475 1.75171C9.10744 2.5159 8.79246 3.12133 8.15712 3.55724C8.09251 3.60029 8.07905 3.64604 8.07905 3.716C8.07905 5.15827 8.08174 6.60054 8.07905 8.0455C8.07905 8.63748 7.67523 9.06531 7.09373 9.06531C5.38424 9.07069 3.67744 9.068 1.96794 9.06531C1.75257 9.06531 1.55066 9.00612 1.37567 8.87158C1.093 8.65631 0.982624 8.3657 0.982624 8.02128C0.985316 6.58439 0.982624 5.1502 0.982624 3.71331C0.982624 3.6568 0.985316 3.60837 0.923397 3.568C0.557269 3.3339 0.288057 3.01101 0.134606 2.59662C0.0753793 2.43787 0.0430739 2.26835 0 2.10421C0 1.98312 0 1.86204 0 1.73826C0.00538424 1.7248 0.0134606 1.71404 0.0161527 1.70059C0.121145 0.952544 0.551884 0.454746 1.23299 0.164139C1.41875 0.0834149 1.62873 0.053816 1.82795 0ZM3.6209 4.51517C3.66936 4.48557 3.76358 4.4479 3.83089 4.38063C4.45546 3.76174 5.07734 3.13748 5.69922 2.5159C5.72075 2.49437 5.7396 2.47554 5.75575 2.45132C5.89843 2.26566 5.81229 2.01003 5.58615 1.94545C5.44885 1.90778 5.34116 1.9589 5.24694 2.05308C4.61968 2.68004 3.99241 3.307 3.36515 3.93395C3.34361 3.95548 3.31938 3.9797 3.30323 4.0066C3.23593 4.11155 3.22516 4.21918 3.28439 4.33219C3.34361 4.45059 3.44053 4.5044 3.6209 4.51517ZM5.84728 4.84614C5.82305 4.70621 5.74498 4.58513 5.62922 4.53938C5.49192 4.48557 5.36809 4.51517 5.26579 4.62011C4.62775 5.25783 3.98972 5.89286 3.35438 6.53327C3.21439 6.67319 3.21439 6.86424 3.34092 6.99339C3.47014 7.12794 3.66667 7.12794 3.80935 6.98532C4.44738 6.35029 5.08541 5.71257 5.72075 5.07216C5.77729 5.01027 5.80421 4.92148 5.84728 4.84614Z" fill="#7B858F"/>
      <path d="M2.92359 9.71113C2.97743 9.71113 3.01781 9.71113 3.06089 9.71113C4.39618 9.71113 5.73147 9.71113 7.06945 9.71113C7.80979 9.71113 8.41551 9.29136 8.63896 8.60521C8.69549 8.42762 8.72242 8.23388 8.72242 8.04821C8.73049 6.71358 8.7278 5.37894 8.72511 4.04161C8.72511 3.97703 8.74126 3.93129 8.79241 3.88554C9.32007 3.42272 9.60812 2.83613 9.67273 2.13921C9.67543 2.11769 9.67812 2.09885 9.68619 2.05042C9.83157 2.12576 9.97156 2.18496 10.0981 2.26837C10.7011 2.66661 11.0349 3.22091 10.9973 3.95281C10.965 4.6013 10.6473 5.10717 10.1169 5.47312C10.0362 5.52962 10.0146 5.58075 10.0146 5.67224C10.0173 7.10643 10.0173 8.54332 10.0146 9.97752C10.0146 10.3919 9.84234 10.7148 9.46275 10.8951C9.32814 10.9597 9.16662 10.992 9.01855 10.9946C7.31713 11 5.61571 11 3.91429 10.9973C3.34087 10.9973 2.92897 10.5776 2.9209 10.0044C2.9209 9.91294 2.92359 9.82145 2.92359 9.71113Z" fill="#7B858F"/>
    </g>
    <defs>
      <clipPath id="clip0_4066_22970">
        <rect width="11" height="11" fill="white"/>
      </clipPath>
    </defs>
  </svg>


</template>
<script>
export default {
  name: 'AmenityIcon',
}
</script>
<style lang="scss" scoped>
</style>