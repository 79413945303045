<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'single-property-marketplace-carousel': true,
  }">
    <div class="location-main-title">Обзаведи този имот</div>
    <div class="property-marketplace-nav d-flex slider">
      <div class="me-2 slider__item" v-if="fridge === 'no'">Хладилник</div>
      <div class="me-2 slider__item" v-if="oven === 'no'">Готварска печка</div>
      <div class="me-2 slider__item" v-if="microwave === 'no'"> Микровълнова</div>
      <div class="me-2 slider__item" v-if="dishwasher === 'no'"> Съдомиялна</div>
      <div class="me-2 slider__item" v-if="tv === 'no'">Телевизор</div>
      <div class="me-2 slider__item" v-if="sound === 'no'">Озвучение</div>
      <div class="me-2 slider__item" v-if="decorativelights === 'no'">Декоративно осветление</div>
      <div class="me-2 slider__item" v-if="bed === 'no'">Легло</div>
      <div class="me-2 slider__item" v-if="sofa === 'no'">Мека мебел</div>
      <div class="me-2 slider__item" v-if="kitchentable === 'no'">Кухненска маса</div>
      <div class="me-2 slider__item" v-if="coffeetable === 'no'">Холна маса</div>
    </div>
    <div class="marketplace-carousel   ">
      <Carousel v-bind="settings" :breakpoints="breakpoints">
        <Slide v-for="(product, product_index) in products" :key="product_index">
          <div class="slider_item">
            <MarketplaceAd
                :id="product.id"
                :labelnew="true /* todo:! */"
                :labeldiscount="true"
                :title="product.title"
                :category="product.categories.length > 0 ? $t(product.categories[0].title) : ''"
                :regularamount="product.price"
                :discountedamount="product.promoPrice"
                :image="product.mainImage !== null ? product.mainImage.src : ''"
            />
          </div>

        </Slide>
      </Carousel>
    </div>
  </div>

</template>

