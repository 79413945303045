<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="property-specs-nav ">
      <div class="col-md-6 d-flex justify-content-between">
        <a href="#description">{{ $t('description') }}</a>
        <a href="#features">{{ $t('features') }}</a>
        <a href="#advantages">{{ $t('advantages') }}</a>
        <a href="#location">{{ $t('location') }}</a>
      </div>
    </div>

  </div>

</template>

