<template>

  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.51201 5.08821C6.15318 5.08821 5.85316 5.08821 5.51785 5.08821C5.51785 4.42791 5.51785 3.77941 5.51785 3.16628C4.43546 2.98942 3.38836 2.82435 2.30596 2.64748C2.04712 3.36083 1.77064 4.12725 1.49416 4.89366C1.3765 5.2238 1.24709 5.54805 1.14708 5.8841C1.09414 6.05507 1.0059 6.10813 0.829423 6.10223C0.570589 6.09044 0.305872 6.09633 0.00585938 6.09633C0.476467 4.78164 0.93531 3.50233 1.38827 2.2289C0.199985 1.40943 -0.017671 1.13234 0.0352724 0.554588C1.84123 1.77495 3.88838 2.09331 6.00023 2.09331C8.11208 2.09331 10.1533 1.77495 12.0005 0.531006C11.9534 1.46249 11.2593 1.81032 10.6122 2.22301C11.071 3.50233 11.5299 4.78164 12.0005 6.09633C11.6652 6.09633 11.3593 6.10813 11.0651 6.08454C10.9946 6.07865 10.9063 5.96074 10.8769 5.8782C10.5004 4.85239 10.1357 3.82658 9.76509 2.80076C9.74156 2.73591 9.71214 2.67696 9.70038 2.64748C8.64151 2.81845 7.60029 2.98352 6.5179 3.16039C6.51201 3.76173 6.51201 4.41023 6.51201 5.08821Z" fill="#7B858F"/>
  </svg>



</template>
<script>
export default {
  name: 'AmenityIcon',
}
</script>
<style lang="scss" scoped>
</style>