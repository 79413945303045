<template>

  <svg width="17" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.18652 1.10266L10.8896 10.8058" stroke="#E64C4C" stroke-width="1.94062" stroke-linecap="round"/>
    <path d="M10.8901 1.10266L1.18702 10.8058" stroke="#E64C4C" stroke-width="1.94062" stroke-linecap="round"/>
  </svg>
</template>
<script>
export default {
  name: 'CrossIcon',
}
</script>
<style lang="scss" scoped>
</style>