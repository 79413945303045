<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="property-description">
      <div class="'property-description-title'">{{ $t('description') }}</div>
      <div class="property-description-paragraph">{{ description }} </div>
    </div>
  </div>
</template>

