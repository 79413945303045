<template>

  <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4066_22960)">
      <path d="M0.283197 13C0.013795 12.844 -0.0281898 12.7626 0.0172937 12.4505C0.269202 10.7378 0.521111 9.02164 0.773019 7.30549C0.975945 5.92172 1.17887 4.53455 1.3818 3.15078C1.3853 3.13721 1.3888 3.12704 1.39229 3.10669C2.22849 3.10669 3.06469 3.10669 3.91138 3.10669C3.91138 5.63343 3.91138 8.16696 3.91138 10.714C3.97436 10.714 4.02684 10.714 4.07582 10.714C6.22054 10.714 8.36176 10.714 10.5065 10.714C10.8494 10.714 10.9928 10.8497 10.9928 11.1821C10.9928 11.6162 10.9823 12.0537 10.9963 12.4879C11.0033 12.7253 10.9298 12.8982 10.7059 13C7.23167 13 3.75743 13 0.283197 13ZM2.35095 11.1007C2.35444 10.8938 2.17601 10.7208 1.96609 10.7174C1.75266 10.714 1.57073 10.8836 1.56723 11.0871C1.56023 11.2974 1.74217 11.4772 1.95909 11.4772C2.16901 11.4772 2.34745 11.3042 2.35095 11.1007Z" fill="#7B858F"/>
      <path d="M1.56728 2.28594C1.56728 1.63136 1.56378 0.990347 1.57078 0.352726C1.57078 0.162797 1.73872 0.016958 1.93465 0C1.96614 0 1.99413 0 2.02561 0C4.61117 0 7.19324 0 9.7788 0C10.4855 0 11.0173 0.518915 10.9964 1.18706C10.9789 1.76702 10.482 2.25541 9.88376 2.28255C9.84177 2.28594 9.79629 2.28594 9.7543 2.28594C7.07428 2.28594 4.39425 2.28594 1.71423 2.28594C1.66874 2.28594 1.62326 2.28594 1.56728 2.28594Z" fill="#7B858F"/>
      <path d="M6.67194 6.14557C6.67194 5.88442 6.67194 5.64362 6.67194 5.39264C6.93085 5.39264 7.18625 5.39264 7.45566 5.39264C7.45566 5.63683 7.45566 5.88442 7.45566 6.14557C7.54312 6.14557 7.6166 6.14557 7.68657 6.14557C8.12041 6.14557 8.55076 6.14557 8.9846 6.14557C9.2645 6.14557 9.42194 6.2982 9.42544 6.56952C9.42544 6.67806 9.42544 6.78659 9.42544 6.89512C9.63886 6.90529 9.83829 6.9019 10.0342 6.93243C10.6185 7.024 11.0314 7.5497 10.9929 8.12966C10.9544 8.69945 10.4611 9.16749 9.86978 9.19123C9.70534 9.19801 9.5374 9.19801 9.37296 9.19123C9.29949 9.18784 9.2715 9.21836 9.24001 9.27941C9.12455 9.48291 9.00559 9.68301 8.87264 9.87633C8.84465 9.91703 8.76768 9.95095 8.7117 9.95095C7.6131 9.95434 6.511 9.95434 5.4124 9.95095C5.35642 9.95095 5.28645 9.91364 5.25146 9.87294C4.89459 9.45578 4.70566 8.97417 4.70566 8.42812C4.70216 7.81085 4.70566 7.19358 4.70566 6.57631C4.70566 6.2982 4.8631 6.14557 5.15349 6.14557C5.60833 6.14557 6.05966 6.14557 6.5145 6.14557C6.56348 6.14557 6.61246 6.14557 6.67194 6.14557ZM9.43594 7.6684C9.43594 7.91938 9.43594 8.17036 9.43594 8.42812C9.56889 8.42812 9.69834 8.43151 9.82779 8.42812C10.0412 8.42134 10.2057 8.25515 10.2092 8.05504C10.2127 7.84816 10.0447 7.67858 9.8243 7.67179C9.69834 7.66501 9.57239 7.6684 9.43594 7.6684Z" fill="#7B858F"/>
      <path d="M5.49257 3.10333C6.55269 3.10333 7.59881 3.10333 8.65892 3.10333C8.54346 3.4391 8.43151 3.76809 8.31955 4.09707C8.28806 4.18525 8.26007 4.27343 8.22508 4.36162C8.1586 4.5312 8.02915 4.61938 7.84722 4.62277C7.32941 4.62616 6.8081 4.62616 6.29028 4.62277C6.11535 4.62277 5.9824 4.54137 5.92292 4.38536C5.77247 3.97497 5.63602 3.5612 5.49607 3.14742C5.48908 3.13386 5.49257 3.12029 5.49257 3.10333Z" fill="#7B858F"/>
    </g>
    <defs>
      <clipPath id="clip0_4066_22960">
        <rect width="11" height="13" fill="white"/>
      </clipPath>
    </defs>
  </svg>



</template>
<script>
export default {
  name: 'AmenityIcon',
}
</script>
<style lang="scss" scoped>
</style>