<template>

  <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_94_4905)">
      <path d="M3.35969 7.50382C2.3985 7.50382 1.43731 7.50382 0.47612 7.50382C0.0803354 7.50382 -0.0704397 7.27859 0.0332182 6.84385C0.509102 4.85345 0.980274 2.85781 1.45616 0.862179C1.54568 0.479813 1.63991 0.401245 2.00272 0.401245C2.91679 0.401245 3.83086 0.401245 4.74494 0.401245C5.09832 0.401245 5.19726 0.479813 5.28679 0.862179C5.76738 2.86305 6.24327 4.86916 6.71915 6.87004C6.81338 7.26812 6.65319 7.49858 6.28567 7.49858C5.31506 7.50382 4.33502 7.50382 3.35969 7.50382Z" fill="#7B858F"/>
      <path d="M3.74141 11.2646C4.12777 11.2646 4.46702 11.2646 4.81097 11.2646C5.07954 11.2646 5.25387 11.4375 5.24916 11.6889C5.24445 11.9298 5.07954 12.0974 4.82039 12.0974C3.8592 12.0974 2.89801 12.0974 1.93682 12.0974C1.66354 12.0974 1.49392 11.9298 1.49863 11.6732C1.50334 11.4217 1.66825 11.2646 1.94624 11.2646C2.2902 11.2646 2.62944 11.2646 2.98753 11.2646C2.98753 10.1489 2.98753 9.05946 2.98753 7.94379C3.24197 7.94379 3.47755 7.94379 3.74141 7.94379C3.74141 9.03851 3.74141 10.1385 3.74141 11.2646Z" fill="#7B858F"/>
      <path d="M4.49499 7.93335C4.75885 7.93335 4.98972 7.93335 5.23473 7.93335C5.23473 8.20048 5.32897 8.4938 5.07925 8.66665C4.9803 8.73475 4.80125 8.74522 4.6976 8.69284C4.41018 8.54094 4.51855 8.22143 4.49499 7.93335Z" fill="#7B858F"/>
    </g>
    <defs>
      <clipPath id="clip0_94_4905">
        <rect width="7" height="12" fill="white" transform="translate(0 0.102661)"/>
      </clipPath>
    </defs>
  </svg>


</template>
<script>
export default {
  name: 'DecorativeLightingIcon',
}
</script>
<style lang="scss" scoped>
</style>