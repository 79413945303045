<template>

  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 10.5194C10.8081 10.5194 10.6277 10.5194 10.43 10.5194C10.43 10.7138 10.43 10.9023 10.43 11.1026C10.2322 11.1026 10.0489 11.1026 9.85407 11.1026C9.85407 10.9172 9.85407 10.7288 9.85407 10.5314C6.95137 10.5314 4.06322 10.5314 1.16052 10.5314C1.16052 10.7168 1.16052 10.9052 1.16052 11.1056C0.965648 11.1056 0.782411 11.1056 0.584632 11.1056C0.584632 10.9172 0.584632 10.7318 0.584632 10.5254C0.383945 10.5254 0.194892 10.5254 0.00292969 10.5254C0.00292969 9.72688 0.00292969 8.9463 0.00292969 8.15375C3.66766 8.15375 7.32948 8.15375 11.0029 8.15375C11 8.93433 11 9.71791 11 10.5194ZM7.52435 9.042C6.17189 9.042 4.82815 9.042 3.4786 9.042C3.4786 9.23939 3.4786 9.42781 3.4786 9.61324C4.83397 9.61324 6.1777 9.61324 7.52435 9.61324C7.52435 9.41884 7.52435 9.23341 7.52435 9.042Z" fill="#7B858F"/>
    <path d="M10.4182 0.102661C10.4182 2.08254 10.4182 4.05644 10.4182 6.03931C7.14319 6.03931 3.86821 6.03931 0.587402 6.03931C0.587402 4.06242 0.587402 2.08852 0.587402 0.102661C3.85948 0.102661 7.13156 0.102661 10.4182 0.102661ZM1.37852 0.841378C1.37852 2.33377 1.37852 3.81419 1.37852 5.29162C4.12997 5.29162 6.87561 5.29162 9.62125 5.29162C9.62125 3.80223 9.62125 2.32479 9.62125 0.841378C6.8727 0.841378 4.12997 0.841378 1.37852 0.841378Z" fill="#7B858F"/>
    <path d="M8.34144 7.5287C6.44218 7.5287 4.55746 7.5287 2.6582 7.5287C2.71346 7.24757 2.76291 6.97242 2.81817 6.70325C2.82399 6.67932 2.86471 6.6554 2.89379 6.64642C2.92288 6.63745 2.95778 6.64343 2.98687 6.64343C4.66217 6.64343 6.33456 6.64343 8.00987 6.64044C8.13203 6.64044 8.18438 6.67035 8.20183 6.79895C8.23964 7.0412 8.2949 7.28046 8.34144 7.5287Z" fill="#7B858F"/>
    <path d="M1.96045 1.4425C4.32507 1.4425 6.68097 1.4425 9.04559 1.4425C9.04559 2.53114 9.04559 3.61379 9.04559 4.70243C6.68388 4.70243 4.32798 4.70243 1.96045 4.70243C1.96045 3.61978 1.96045 2.54011 1.96045 1.4425Z" fill="#7B858F"/>
  </svg>



</template>
<script>
export default {
  name: 'CrossIcon',
}
</script>
<style lang="scss" scoped>
</style>