<template>

  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.95 0.107877C8.91709 0.107877 10.8842 0.107877 12.8513 0.107877C13.574 0.107877 13.862 0.389546 13.862 1.0885C13.862 4.77628 13.862 8.46928 13.862 12.1571C13.862 12.783 13.5522 13.0855 12.9056 13.0855C8.92796 13.0855 4.94489 13.0855 0.967244 13.0855C0.304303 13.0855 0 12.783 0 12.1414C0 8.4432 0 4.74499 0 1.04677C0 0.394763 0.304303 0.102661 0.994413 0.102661C2.9778 0.107877 4.96118 0.107877 6.95 0.107877ZM6.93914 11.6459C8.27589 11.6459 9.6072 11.6459 10.9439 11.6459C11.7699 11.6459 12.2644 11.1816 12.2644 10.3888C12.2753 8.75095 12.2698 7.11309 12.2644 5.48046C12.259 4.68239 11.7482 4.20773 10.9222 4.20773C8.27045 4.20773 5.6187 4.20773 2.96694 4.20773C2.12468 4.20773 1.61932 4.69283 1.61932 5.50132C1.61932 7.11309 1.64105 8.72487 1.60845 10.3314C1.59215 11.0095 2.0812 11.6772 2.98867 11.6511C4.30368 11.625 5.61869 11.6459 6.93914 11.6459ZM3.907 3.26883C4.30911 3.26362 4.65145 2.935 4.64058 2.55423C4.63514 2.16824 4.29825 1.85527 3.89071 1.86049C3.48316 1.86049 3.15169 2.18388 3.15712 2.56987C3.16256 2.95587 3.49945 3.27405 3.907 3.26883ZM9.94411 1.86049C9.53656 1.8657 9.20509 2.1891 9.21053 2.57509C9.22139 2.95587 9.56373 3.27405 9.96584 3.27405C10.3679 3.26883 10.7049 2.94022 10.694 2.55944C10.6886 2.16302 10.3571 1.85527 9.94411 1.86049ZM6.92283 1.86049C6.51529 1.86049 6.17839 2.18388 6.17839 2.56466C6.17839 2.94543 6.52072 3.26883 6.92283 3.26883C7.32494 3.26883 7.66729 2.94022 7.66729 2.55944C7.67273 2.17345 7.33038 1.86049 6.92283 1.86049Z" fill="#7B858F"/>
    <path d="M6.92275 10.8634C5.60774 10.8634 4.28729 10.8634 2.97228 10.8634C2.55387 10.8634 2.43433 10.7435 2.43433 10.3314C2.43433 8.73005 2.43433 7.12871 2.43433 5.52737C2.43433 5.13095 2.57017 4.99011 2.97772 4.99011C5.61861 4.99011 8.26494 4.99011 10.9058 4.99011C11.3079 4.99011 11.4438 5.12051 11.4438 5.50129C11.4438 7.11828 11.4438 8.74048 11.4438 10.3575C11.4438 10.7435 11.3188 10.8582 10.9004 10.8582C9.57452 10.8634 8.24863 10.8634 6.92275 10.8634ZM6.91733 6.29414C8.07475 6.29414 9.22675 6.29414 10.3842 6.29414C10.683 6.29414 10.8569 6.14287 10.8569 5.90815C10.8569 5.67864 10.6667 5.52215 10.3787 5.51694C10.3624 5.51694 10.3407 5.51694 10.3244 5.51694C8.05845 5.51694 5.79249 5.51694 3.52654 5.51694C3.2005 5.51694 2.99946 5.67342 3.00489 5.91336C3.00489 6.16373 3.18965 6.29414 3.53742 6.29414C4.66224 6.29414 5.78707 6.29414 6.91733 6.29414Z" fill="#7B858F"/>
  </svg>


</template>
<script>
export default {
  name: 'CrossIcon',
}
</script>
<style lang="scss" scoped>
</style>