import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";

export default {
    name: 'SinglePropertyUtilities',
    props: {
        lowrangetotal: {
            type: String,
            default: ''
        },
        highrangetotal: {
            type: String,
            default: ''
        },
        lowrangeelectro: {
            type: String,
            default: ''
        },
        highrangeelectro: {
            type: String,
            default: ''
        },
        lowrangeheating: {
            type: String,
            default: ''
        },
        highrangeheating: {
            type: String,
            default: ''
        },
        lowrangewater: {
            type: String,
            default: ''
        },
        highrangewater: {
            type: String,
            default: ''
        },
        lowrangecomunal: {
            type: String,
            default: ''
        },
        highrangecomunal: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },

    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox

    },
    mounted() {
    }

}