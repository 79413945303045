<template>

  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4066_22951)">
      <path d="M4.34082 0C4.34082 1.79853 4.34082 3.6 4.34367 5.39853C4.34367 6.33447 4.34367 7.27335 4.34367 8.20929C4.34367 8.25623 4.34367 8.30024 4.34367 8.35599C5.56435 8.35599 6.77077 8.35599 7.9943 8.35599C7.99715 8.31491 8 8.27384 8 8.23276C8 5.8709 8 3.50905 8 1.14719C8 0.674817 7.81462 0.31687 7.39822 0.105623C7.2984 0.0557457 7.18431 0.0352078 7.07594 0C6.16328 0 5.25348 0 4.34082 0ZM5.35045 4.53301C5.35045 4.68557 5.35045 4.83814 5.35045 4.98777C5.35045 4.99658 5.35045 5.00244 5.35045 5.01125C5.34189 5.22836 5.19643 5.38386 5.0025 5.378C4.81141 5.37506 4.66595 5.22249 4.6631 5.01418C4.66025 4.69438 4.66025 4.37457 4.6631 4.05477C4.66595 3.84059 4.81711 3.68509 5.01105 3.68802C5.20214 3.69095 5.34474 3.84059 5.35045 4.05183C5.3533 4.2132 5.35045 4.37457 5.35045 4.53301Z" fill="#7B858F"/>
      <path d="M0.923986 0C0.758567 0.0704156 0.573184 0.114425 0.427729 0.214181C0.111152 0.43423 -0.00293016 0.771638 -0.00293016 1.15599C-7.82013e-05 2.89291 -0.00293016 4.62983 -0.00293016 6.36675C-0.00293016 6.97995 -0.00293016 7.59315 -0.00293016 8.20636C-0.00293016 8.2533 -0.00293016 8.29731 -0.00293016 8.35306C1.21775 8.35306 2.42702 8.35306 3.6534 8.35306C3.6534 8.30611 3.6534 8.25623 3.6534 8.20929C3.6534 5.52469 3.6534 2.83716 3.6534 0.152567C3.6534 0.102689 3.6534 0.0498777 3.65625 0C2.74645 0 1.83664 0 0.923986 0ZM2.65233 4.54474C2.65233 4.37751 2.64948 4.21027 2.65233 4.04303C2.65803 3.84059 2.80064 3.69389 2.98887 3.69095C3.17996 3.68802 3.33397 3.83765 3.33682 4.04597C3.34252 4.37457 3.34252 4.70024 3.33682 5.02885C3.33397 5.22836 3.18281 5.37799 2.99743 5.38093C2.80634 5.38386 2.65803 5.2313 2.65233 5.02298C2.64662 4.86455 2.65233 4.70611 2.65233 4.54474Z" fill="#7B858F"/>
      <path d="M1.85668 11.2284C3.29127 11.2284 4.70873 11.2284 6.14332 11.2284C6.14332 11.3663 6.14046 11.5042 6.14332 11.6391C6.14902 11.7917 6.22032 11.9091 6.36007 11.9677C6.49697 12.0264 6.63102 12.0059 6.72513 11.8915C6.78217 11.8181 6.8164 11.7125 6.82781 11.6186C6.84492 11.4924 6.83066 11.3633 6.83066 11.2401C6.96471 11.2225 7.08734 11.2225 7.20428 11.1932C7.64635 11.0846 7.97433 10.6856 7.98574 10.2308C8 9.86701 7.99715 9.50319 8 9.13938C8 9.12177 7.99715 9.10123 7.99144 9.07483C5.33048 9.07483 2.67522 9.07483 0.0142603 9.07483C0.0114083 9.08657 0 9.10124 0 9.11591C0.00285244 9.48559 -0.00285244 9.8582 0.00855589 10.2279C0.0256681 10.7589 0.464884 11.2078 1.01533 11.2225C1.06096 11.2225 1.10374 11.2225 1.16934 11.2225C1.16934 11.3809 1.16364 11.5276 1.17219 11.6773C1.18075 11.8416 1.30339 11.9677 1.46025 11.9912C1.60856 12.0147 1.76257 11.9296 1.81961 11.7858C1.84242 11.7301 1.85098 11.6655 1.85383 11.6039C1.85954 11.4836 1.85668 11.3633 1.85668 11.2284ZM3.99287 9.44158C4.12977 9.44158 4.26667 9.43864 4.40357 9.44158C4.5918 9.44745 4.73155 9.59708 4.7344 9.78779C4.73726 9.98437 4.5975 10.1399 4.40357 10.1428C4.13832 10.1487 3.87308 10.1487 3.60784 10.1428C3.41105 10.1399 3.2713 9.9873 3.2713 9.79072C3.2713 9.59415 3.4139 9.44451 3.60784 9.43864C3.73333 9.43864 3.86453 9.44158 3.99287 9.44158Z" fill="#7B858F"/>
    </g>
    <defs>
      <clipPath id="clip0_4066_22951">
        <rect width="8" height="12" fill="white" transform="matrix(-1 0 0 1 8 0)"/>
      </clipPath>
    </defs>
  </svg>



</template>
<script>
export default {
  name: 'AmenityIcon',
}
</script>
<style lang="scss" scoped>
</style>