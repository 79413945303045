<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="features-main-container">
      <div class="description-container">
        <div class="single-feature-container">
          <div class="features-title-1"> {{ title }}</div>
          <div class="features-description-1"> {{ description }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

