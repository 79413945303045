<template>

  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.12367 13.2629C6.10225 13.435 3.52656 12.4387 2.26045 10.0706C1.06499 7.83815 1.51057 5.16229 3.38528 3.3158C5.19478 1.53189 7.99869 1.04679 10.3353 2.11088C12.7914 3.23234 13.9869 5.67347 13.8184 7.77556C13.7532 7.71818 13.7043 7.68167 13.6609 7.63994C13.2642 7.21222 12.8077 6.87839 12.2698 6.62802C12.1828 6.58629 12.0959 6.48196 12.0687 6.38807C11.5253 4.59895 10.3136 3.50357 8.39537 3.17496C6.16202 2.79419 3.82543 4.34858 3.33638 6.49761C2.82559 8.77183 4.23297 10.973 6.5913 11.5624C6.77062 11.6094 6.863 11.6824 6.93907 11.8389C7.1999 12.397 7.60202 12.8456 8.12367 13.2629Z" fill="#7B858F"/>
    <path d="M6.56416 10.7435C4.98288 10.274 3.85263 8.63616 4.05368 7.1287C4.29821 5.3135 5.75451 4.00426 7.61291 3.92602C9.52023 3.84256 10.8787 5.20396 11.1667 6.30456C8.47691 5.97073 6.21639 8.13541 6.56416 10.7435Z" fill="#7B858F"/>
    <path d="M10.6016 13.2941C8.80841 13.2941 7.34668 11.8962 7.34668 10.1801C7.34668 8.45879 8.80298 7.06088 10.5907 7.05566C12.3839 7.05566 13.8457 8.45358 13.8457 10.1697C13.8457 11.891 12.3894 13.2941 10.6016 13.2941ZM12.6285 10.1801C12.6339 9.11602 11.721 8.2345 10.6016 8.22407C9.49309 8.21364 8.57475 9.08473 8.55845 10.1592C8.54215 11.2285 9.47679 12.1309 10.5962 12.1257C11.7101 12.1257 12.623 11.2494 12.6285 10.1801Z" fill="#7B858F"/>
    <path d="M0.793354 11.745C0.793354 11.4634 0.793354 11.2339 0.793354 10.9783C1.05962 10.9783 1.31501 10.9783 1.59758 10.9783C1.59758 11.2182 1.59758 11.4634 1.59758 11.7294C1.87471 11.7294 2.12467 11.7294 2.39637 11.7294C2.39637 11.9902 2.39637 12.2354 2.39637 12.5118C2.1464 12.5118 1.89101 12.5118 1.61388 12.5118C1.61388 12.7778 1.61388 13.0178 1.61388 13.2786C1.34218 13.2786 1.08679 13.2786 0.798786 13.2786C0.798786 13.0386 0.798786 12.7935 0.798786 12.5275C0.521655 12.5275 0.271697 12.5275 0 12.5275C0 12.2666 0 12.0215 0 11.745C0.249961 11.745 0.505355 11.745 0.793354 11.745Z" fill="#7B858F"/>
    <path d="M12.2263 0.102661C12.5089 0.102661 12.748 0.102661 13.0197 0.102661C13.0197 0.316521 13.0197 0.530381 13.0197 0.770321C13.3023 0.770321 13.5577 0.770321 13.8294 0.770321C13.8294 1.03634 13.8294 1.27628 13.8294 1.5423C13.574 1.5423 13.3186 1.5423 13.036 1.5423C13.036 1.81354 13.036 2.0587 13.036 2.3195C12.7589 2.3195 12.5089 2.3195 12.2318 2.3195C12.2318 2.07435 12.2318 1.82919 12.2318 1.55795C11.9492 1.55795 11.6938 1.55795 11.4221 1.55795C11.4221 1.29193 11.4221 1.05199 11.4221 0.78597C11.6775 0.78597 11.9329 0.78597 12.2263 0.78597C12.2263 0.535597 12.2263 0.321737 12.2263 0.102661Z" fill="#7B858F"/>
    <path d="M10.5852 9.01689C11.2699 9.01167 11.8024 9.51763 11.8024 10.1749C11.8024 10.8321 11.2753 11.338 10.5907 11.338C9.91685 11.3328 9.39519 10.8373 9.38432 10.1905C9.37889 9.53328 9.90054 9.0221 10.5852 9.01689Z" fill="#7B858F"/>
  </svg>


</template>
<script>
export default {
  name: 'CrossIcon',
}
</script>
<style lang="scss" scoped>
</style>