<template>

  <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4066_22956)">
      <path d="M11.4579 9.1622C8.17768 9.1622 4.92027 9.1622 1.63554 9.1622C1.08884 6.15274 0.546697 3.14328 0 0.116017C0.0501139 0.111565 0.0911162 0.102661 0.132118 0.102661C0.514806 0.102661 0.892939 0.102661 1.27563 0.102661C1.81321 0.107113 2.24601 0.45881 2.3508 0.975227C2.72893 2.87617 3.10251 4.77712 3.47608 6.67806C3.49886 6.79381 3.52164 6.90511 3.54897 7.03867C3.61731 7.03867 3.68565 7.03867 3.75399 7.03867C5.918 7.03867 8.08656 7.03867 10.2506 7.03867C11.0205 7.03867 11.4624 7.4705 11.4624 8.23177C11.4579 8.53449 11.4579 8.83722 11.4579 9.1622Z" fill="#7B858F"/>
      <path d="M1.93616 13.9836C1.83593 14.5312 1.73115 15.0787 1.63092 15.6263C1.57625 15.9157 1.3758 16.0982 1.107 16.1071C0.847322 16.116 0.624087 15.9469 0.560306 15.6887C0.546639 15.6263 0.537527 15.5551 0.551195 15.4928C0.870101 13.7744 1.18901 12.0559 1.51247 10.3331C1.51703 10.3063 1.53069 10.2796 1.5398 10.2485C1.89516 10.2485 2.25051 10.2485 2.6332 10.2485C2.46919 11.1299 2.30974 12.007 2.14573 12.8973C4.97944 12.8973 7.8086 12.8973 10.6605 12.8973C10.5785 12.0203 10.4965 11.1478 10.4145 10.2574C10.779 10.2574 11.1389 10.2574 11.5125 10.2574C11.5398 10.5245 11.5671 10.7916 11.5899 11.0632C11.7266 12.51 11.8587 13.9613 11.9908 15.4082C11.9999 15.5061 12.0045 15.6085 11.9817 15.7065C11.9225 15.9558 11.6856 16.1249 11.4305 16.1116C11.1571 16.0938 10.9384 15.8934 10.9111 15.6263C10.861 15.1277 10.82 14.6291 10.7699 14.135C10.7653 14.0904 10.7608 14.0504 10.7516 13.9925C7.82227 13.9836 4.89288 13.9836 1.93616 13.9836Z" fill="#7B858F"/>
    </g>
    <defs>
      <clipPath id="clip0_4066_22956">
        <rect width="12" height="16" fill="white" transform="translate(0 0.102661)"/>
      </clipPath>
    </defs>
  </svg>




</template>
<script>
export default {
  name: 'AmenityIcon',
}
</script>
<style lang="scss" scoped>
</style>