import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";

export default {
    name: 'SinglePropertyDescription',
    props: {
        description: {
            type: String,
            default: ''
        },
        email: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        time: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        propertyid: {
            type: String,
            default: ''
        },

    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox

    },
    mounted() {
    }
}