<template>

  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.81792 0.103501C7.45328 0.103501 8.87687 0.256783 10.2122 0.828644C10.7064 1.04088 11.1828 1.34155 11.5829 1.69528C12.1476 2.19639 12.1417 2.86848 11.6476 3.44034C11.2476 3.90608 10.7181 4.17727 10.1593 4.39541C8.54745 5.01443 6.86502 5.16771 5.15319 5.06749C4.01785 5.00264 2.90015 4.83167 1.84717 4.38362C1.41185 4.20086 0.982425 3.96504 0.611821 3.67616C-0.199977 3.04534 -0.199975 2.1905 0.588293 1.51841C1.24714 0.952449 2.04718 0.675362 2.87662 0.463125C3.90608 0.203724 4.9473 0.0917099 5.81792 0.103501Z" fill="#7B858F"/>
    <path d="M6.51201 9.08821C6.15318 9.08821 5.85316 9.08821 5.51785 9.08821C5.51785 8.42791 5.51785 7.77941 5.51785 7.16628C4.43546 6.98942 3.38836 6.82435 2.30596 6.64748C2.04712 7.36083 1.77064 8.12725 1.49416 8.89366C1.3765 9.2238 1.24709 9.54805 1.14708 9.8841C1.09414 10.0551 1.0059 10.1081 0.829423 10.1022C0.570589 10.0904 0.305872 10.0963 0.00585938 10.0963C0.476467 8.78164 0.93531 7.50233 1.38827 6.2289C0.199985 5.40943 -0.017671 5.13234 0.0352724 4.55459C1.84123 5.77495 3.88838 6.09331 6.00023 6.09331C8.11208 6.09331 10.1533 5.77495 12.0005 4.53101C11.9534 5.46249 11.2593 5.81032 10.6122 6.22301C11.071 7.50233 11.5299 8.78164 12.0005 10.0963C11.6652 10.0963 11.3593 10.1081 11.0651 10.0845C10.9946 10.0786 10.9063 9.96074 10.8769 9.8782C10.5004 8.85239 10.1357 7.82658 9.76509 6.80076C9.74156 6.73591 9.71214 6.67696 9.70038 6.64748C8.64151 6.81845 7.60029 6.98352 6.5179 7.16039C6.51201 7.76173 6.51201 8.41023 6.51201 9.08821Z" fill="#7B858F"/>
  </svg>



</template>
<script>
export default {
  name: 'AmenityIcon',
}
</script>
<style lang="scss" scoped>
</style>